import api from '../library/axios';
import config from '../config';

export default {
  get({ workspace_id, options }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}`,
      params: options,
    });
  },
  detail({ workspace_id, flow_id }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/${flow_id}/detail`,
    });
  },
  detail_json({ workspace_id, flow_id }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/${flow_id}/detail_json`,
    });
  },
  response({ workspace_id, flow_id, options }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/${flow_id}/response`,
      params: options,
    });
  },
  duplicate({ workspace_id, flow_id }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/duplicate/${flow_id}`,
    });
  },
  getChatbot({ workspace_id, flow_id }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/${flow_id}`,
    });
  },
  save({ workspace_id, payload }) {
    return api({
      method: 'post',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/manage`,
      data: payload,
    });
  },
  preview({ workspace_id, flow_id }) {
    return api({
      method: 'get',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/preview/${flow_id}`,
    });
  },
  publish({ workspace_id, flow_id }) {
    return api({
      method: 'post',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/publish/${flow_id}`,
    });
  },
  archive({ workspace_id, flow_id }) {
    return api({
      method: 'post',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/archive/${flow_id}`,
    });
  },
  delete({ workspace_id, flow_id }) {
    return api({
      method: 'delete',
      baseURL: config.api.baseUrl,
      url: `/api/flow/${workspace_id}/manage/${flow_id}`,
    });
  },
};
